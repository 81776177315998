import cn from 'classnames';
import React from 'react';

import { SkeletonNews } from './SkeletonNews';

import s from './styles.module.css';

type SkeletonsPropsType = {
  className?: string;
};

export const Skeletons = ({ className }: SkeletonsPropsType) => (
  <div className={cn(s.skeletons, className)}>
    <div className={s.skeleton}>
      <SkeletonNews />
    </div>
    <div className={s.skeleton}>
      <SkeletonNews />
    </div>
    <div className={s.skeleton}>
      <SkeletonNews />
    </div>
    <div className={s.skeleton}>
      <SkeletonNews />
    </div>
    <div className={s.skeleton}>
      <SkeletonNews />
    </div>
  </div>
);
