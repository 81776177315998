import cn from 'classnames';
import React, { memo } from 'react';

import s from './styles.module.css';

/**
 * Компонент скелетон для новостей в фиде
 */
export const SkeletonNews = memo(() => (
  <div className={s.container}>
    <div className={s.avatar} />
    <div className={s.textGroup}>
      <div className={s.text} />
      <div className={cn(s.text, s.text2)} />
      <div className={cn(s.text, s.text3)} />
    </div>
  </div>
));
