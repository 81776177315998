import React, { memo } from 'react';

import { useRcmBlock } from 'common/hooks/useRcm';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';

/**
 * Конфигурация для обертки отправки событий в rcm
 * @param rcmKey - имя блока, в котором используется карточка (для поиска blockID)
 * @param position - номер позиции, под которой выводится карточка
 * @param contextItemId - идентификатор страницы, на которой отображается карточка
 */
export type RcmCardType = {
  rcmKey: RCM_BLOCK_TYPE;
  position: number;
  contextItemId?: string;
};

type RecEventsPropsType<T extends Element> = {
  clusterId: CardData['id'] | null;
  render: (ref: React.RefObject<T> | null, onClick: () => void) => JSX.Element;
  rcmKey: RcmCardType['rcmKey'];
  position: RcmCardType['position'];
  contextItemId?: RcmCardType['contextItemId'];
};

/**
 * Обертка отправки событий в RCM
 * @param clusterId - ID кластера карточки, для которой отправить события
 * @param rcmKey - ключ, по которому храним blockID в конфигурации (topNow например)
 * @param position - индекс в списке выводимых карточек
 * @param contextItemId - идентификатор страницы, на которой используется блок
 * (clusterId, pageName или еще что-то)
 * @param render - метод, который вернет компонент внутри этой обертки
 */
export const RecEvents = memo(
  <T extends Element>({
    clusterId,
    rcmKey,
    position,
    contextItemId,
    render,
  }: RecEventsPropsType<T>) => {
    const { ref, onClick } = useRcmBlock<T>({
      rcmKey,
      position,
      contextItemId,
      clusterId,
    });

    return render(ref, onClick);
  },
);
