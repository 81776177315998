import { ImageCropType, CROP_TYPES } from 'common/utils/crop';

/**
 * Генерация crop строки
 * @params config {
 *   width, ширина
 *   height, высота
 *   type, типо кропа
 *  } - конфиг для кропа
 */
export const generateCrop = (config: ImageCropType | null): string => {
  const { width, height, type } = config || {};

  switch (type) {
    case CROP_TYPES.eType: {
      return `e${width}x${height}i`;
    }

    case CROP_TYPES.eTypeO: {
      return `e${width}x${height}o0x0i`;
    }

    case CROP_TYPES.cType: {
      return `c${width}x${height}i`;
    }

    case CROP_TYPES.cTypeO: {
      return `c${width}x${height}o0x0i`;
    }

    case CROP_TYPES.Type: {
      return `${width}x${height}i`;
    }

    case CROP_TYPES.TypeO: {
      return `${width}x${height}o0x0i`;
    }

    default:
      return '';
  }
};
