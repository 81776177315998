import { useMemo } from 'react';
import Typograf from 'typograf';

// Экзмепляр типографа для обработки текста
export const typograf = new Typograf({
  locale: ['ru', 'en-US'],
});

/**
 * Хук для подключения типографа в реакт флоу.
 * @param text - обрабатываемый текст.
 */
export const useTypograf = (text: string | undefined) =>
  useMemo(() => typograf.execute(text || ''), [text]);
