import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ItemList } from 'schema-dts';

import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { JsonLd } from 'utils/jsond';

import { getItemForItemList } from './utils';

type ItemListSchemaPropsType = {
  items: ClusterData[] | { url: string }[];
};

export const ItemListSchema = memo(({ items }: ItemListSchemaPropsType) => {
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);

  const item = getItemForItemList(domainConfig, items);

  return <JsonLd<ItemList> item={item} />;
});
