import { ItemList, WithContext } from 'schema-dts';

/**
 * Функция для получения item для ItemList
 * @param items - список кластеров.
 */
export const getItemForItemList = (
  _domainType: DomainConfigType,
  items: ClusterData[] | { url: string }[],
) =>
  ({
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: items.map((item: any, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      url: item?.url,
    })),
  }) as WithContext<ItemList>;
