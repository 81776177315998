import { createContext, useContext } from 'react';

import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { CARD_TYPES as DESK_CARD_TYPES } from 'desktop/components/Card/constants';

export type CardConfigValue<T = DESK_CARD_TYPES> = {
  getRecBlockType: (props: { index: number }) => RCM_BLOCK_TYPE | null;
  getTop100: (props: {
    /**
     * Объект кластера для дальнейших манипуляций с ним
     */
    card: CardData | undefined;

    /**
     * Объект типа карточки, для более специфичного определения её контента
     */
    type?: T;

    /**
     * Индекс кластера, если есть необходимость его получать непосредственно из кластера.
     * На момент написания данного комментария используется только на мобилке.
     */
    index?: number;

    /**
     * Этаж кластера, если таковой есть
     */
    level?: number | null;
  }) => string;
};

export const initialState: CardConfigValue = {
  getRecBlockType: () => null,
  getTop100: () => '',
};

/**
 * Контекст пробрасывания top100.
 * Содержит функцию, принимающую объект кластера, и возвращающую параметр топ100.
 * Решает проблему, когда данные о кластера находятся внутри карточки, а параметры топ100
 * задаются окружением.
 * @param callback - функция, возвращающая данные о топ100.
 */
const CardConfigContext = createContext<CardConfigValue>(initialState);

export const CardConfigContextProvider = CardConfigContext.Provider;

export const useCardConfig = () => {
  const { getRecBlockType, getTop100 } = useContext(CardConfigContext);

  return {
    getRecBlockType: getRecBlockType ?? initialState?.getRecBlockType,
    getTop100: getTop100 ?? initialState?.getTop100,
  };
};
