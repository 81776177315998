import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { usePublisherAdData } from 'common/hooks/usePublishedAdData';
import { PuidsType } from 'config/constants/common';
import { createNode } from 'utils/createNode';
import { sentryClientSend } from 'utils/sentry/sentry.client';

export enum RamblerPlayerAutoPlay {
  OFF = 'false',
  ON = 'true',
  PLAY_IN_VISIBLE_AREA = 'scroll',
  UNMUTE_IN_VISIBLE_AREA = 'scroll_mute',
}

export enum DockingPosition {
  LeftDown = 'left-down',
  LeftUp = 'left-up',
  MiddleDown = 'middle-down',
  RightDown = 'right-down',
  RightUp = 'right-up',
}

export enum DockingDirection {
  Both = 'both',
  Down = 'down',
}

const VP_ID = 'videoPlayerScript';

interface PropsType {
  clusterUrl: string;
  recordId: string | number | undefined;
  playerRef: MutableRefObject<HTMLDivElement | null>;
  playerTemplateId?: number;
  multiplayerFilterId?: number;
  sspJparams?: PuidsType;
  autoPlay?: RamblerPlayerAutoPlay;
  isLive?: boolean;
  height?: number;
  docking?: DockingPosition;
  dockingDirection?: DockingDirection;
  shouldDrawPlayer?: boolean;
}

/**
 * Хук подключения скрипта для рамблер плеера.
 * дока по видеоплееру https://vp.rambler.ru/docs/player/interfaces/Options.html
 * @param clusterUrl - URL кластера, в который встроен плеер;
 * @param recordId - id видеозаписи в видеоплатформе;
 * @param playerRef - хранилище для компонента плеера;
 * @param playerTemplateId - id шаблона для отображения плеера (например, с рекоммендациями или нет);
 * @param multiplayerFilterId - id шаблона для отображения рекоммендаций и их поведения;
 * @param sspJparams - рекламные настройки для плеера;
 * @param autoPlay - параметр автозапуска плеера;
 * @param isLive - флаг, что видеозапись это видеотрансляция;
 * @param height - высота видеоплеера;
 * @param docking - положение мини-плеера;
 * @param dockingDirection - направление мини-плеера;
 * @param shouldDrawPlayer - флаг, что следует инициализировать плеер.
 * @returns функция отключения защиты от перезагрузки плеера.
 *   Использовать только если изменился playerId, чтобы блок не рисовался дважды в одном и том же месте.
 */
export const useLoadRamblerPlayer = ({
  clusterUrl,
  recordId,
  playerRef,
  playerTemplateId,
  multiplayerFilterId,
  sspJparams,
  autoPlay = RamblerPlayerAutoPlay.ON,
  isLive = false,
  height,
  docking,
  dockingDirection,
  shouldDrawPlayer = true,
}: PropsType) => {
  // Защита для случаев, если хук рискует перерисоваться излишне часто
  const [isInited, setIsInited] = useState(false);

  const { publisherId, publisherScope } = usePublisherAdData();

  useEffect(() => {
    const loadRamblerPlayer = async () => {
      try {
        if (
          shouldDrawPlayer &&
          !isInited &&
          typeof recordId !== 'undefined' &&
          playerRef?.current
        ) {
          window.RamblerPlayer ??= [] as unknown as RamblerPlayer;
          await new Promise((resolve) => window.RamblerPlayer.push(resolve));

          new RamblerPlayer({
            parent: playerRef.current,
            id: recordId,
            playerTemplateId,
            sspJparams,
            sspUserId: publisherId,
            sspUserIdScope: publisherScope,
            live: isLive,
            dockingDirection,
            docking: docking ?? false,
            ...(height && { height }),
            ...(autoPlay && { autoPlay }),
            ...(multiplayerFilterId && { multiplayerFilterId }),
            referrer: clusterUrl,
          });

          setIsInited(true);
        }
      } catch (error) {
        sentryClientSend({
          error: error as Error,
          level: 'error',
        });
      }
    };

    loadRamblerPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDrawPlayer, recordId, isInited]);

  useEffect(() => {
    // Защита от многоразовой загрузки скрипта
    if (document.getElementById(VP_ID)) {
      return;
    }

    createNode({
      tagName: 'script',
      src: 'https://vp.rambler.ru/player/sdk.js',
      id: VP_ID,
    });
  }, [clusterUrl]);

  // console.log('test', isInited)

  return useCallback(() => setIsInited(false), []);
};
